<template v-if="showPage">
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">{{ sprintf($t('pages.module.lecture.titlePattern'), [product.translate.title ?? '']) }}</h2>
        <div>
            <a class="btn btn-danger align-self-center" v-on:click="newDirectory()">
                <span class="svg-icon svg-icon-2">
                    <inline-svg src="/media/icons/duotune/arrows/arr009.svg"/>
                </span>
                {{ $t('pages.module.lecture.directory.newDirectory') }}
            </a>
            <router-link to="/module" class="btn btn-primary align-self-center ms-3">
                {{ $t("menu.modules") }}
                <span class="svg-icon svg-icon-2">
                    <inline-svg src="/media/icons/duotune/arrows/arr024.svg"/>
                </span>
            </router-link>
        </div>
    </div>
    <div class="card" v-if="skeleton">
        <div class="card-body d-flex flex-column p-9">
            <el-skeleton />
        </div>
    </div>
    <div v-else>
        <DraggableNested :list="list" class="border-none"/>
    </div>

    <div class="modal fade" id="kt_modal_directory" ref="directoryModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmitDirectory()" :model="form" ref="directoryForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.lecture.directory.cols.title') }}</label>
                                <el-form-item prop="translate.title" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.translate.title" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('common.status') }}</label>
                                <el-form-item prop="data.active">
                                    <el-radio-group v-model="form.data.active">
                                        <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                        <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_lecture" ref="lectureModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_lecture_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmitLecture()" :model="form" ref="lectureForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_lecture_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.lecture.save.cols.title') }}</label>
                                <el-form-item prop="translate.title" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.translate.title" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.lecture.save.cols.description') }}</label>
                                <el-form-item prop="translate.description">
                                    <CustomCkeditor :model="form.translate.description"  @updateModel="form.translate.description = $event"></CustomCkeditor>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.lecture.save.cols.content') }}</label>
                                <el-form-item prop="translate.content" class="mb-0">
                                    <CustomCkeditor :model="form.translate.content"  @updateModel="form.translate.content = $event"></CustomCkeditor>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('common.status') }}</label>
                                <el-form-item prop="data.active">
                                    <el-radio-group v-model="form.data.active">
                                        <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                        <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import DraggableNested from "@/components/module/lecture/DraggableNested";
import CustomCkeditor from "@/components/custom-ckeditor"

export default {
    name: "index",
    components: {
        DraggableNested,
        CustomCkeditor
    },
    data(){
        return {
            product: {
                translate: {}
            },
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                translate: {},
                data: {}
            },
            showPage: false,
            skeleton: true,
            list: [],
            lectures: {},
            directories: {}
        }
    },
    computed: {
        productID(){
            return this.$route.query.productID ? parseInt(this.$route.query.productID) : undefined;
        }
    },
    provide() {
        return {
            newDirectory: this.newDirectory,
            fetchDirectory: this.fetchDirectory,
            deleteDirectoryRecord: this.deleteDirectoryRecord,
            newLecture: this.newLecture,
            deleteLectureRecord: this.deleteLectureRecord,
            lectures: this.lectures,
            directories: this.directories,
        }
    },
    mounted() {
        this.setCurrentPageBreadcrumbs(this.$t("pages.module.lecture.title"), [this.$t("menu.moduleManagement")]);
        if (this.productID) {
            this.showPage = true;

            this.loadLectureDirectoryMode();

            this.loadProduct();
        } else {
            this.$router.push('/module/lecture/select-product');
        }

        this.eventBus.$on('updateDraggableLecture', (lectureID) => {
            if(this.lectures[lectureID]){
                this.axios.get(this.endpoints['module_lecture']+ '/' + lectureID).then(response => {
                    let data = response.data.data;

                    this.lectures[lectureID] = data;
                });
            }
        });
    },
    beforeUnmount() {
        this.eventBus.$off('cloneQuestion', undefined, true);
    },
    methods: {
        loadProduct(productID = this.productID) {
            this.axios.get(this.endpoints['ecommerce_product'] + '/' + productID).then((response) => {
                let data = response.data.data;
                data.translate = this.resolveDatum(data.translations, this.$root.defaultLanguage.id, 'language_id');
                this.product = data;
            })
        },
        loadLectureDirectoryMode(){
            this.skeleton = true;

            this.axios.get(this.endpoints['module_lecture_directory_mode'], {
                params: {
                    product_id: this.productID
                }
            }).then((response) => {
                let data = response.data.data;
                this.list = data;
            }).finally(() => {
                this.skeleton = false;
            })
        },
        newDirectory(record = null){
            this.form.updateStatus = false;
            this.form.translate = {
                language_id: this.$root.defaultLanguage.id
            };
            this.form.data = {
                sort: (this.list.length + 1),
                product_id: this.productID,
                parent_id: null,
                active: true
            };
            this.form.title = this.$t("pages.module.lecture.directory.newDirectory");

            if(record) {
                let directoryTranslate = this.resolveDatum(record.translations, this.$root.defaultLanguage.id, 'language_id');

                this.form.title = this.sprintf(this.$t("pages.module.lecture.directory.newDirectoryWithParent"), [directoryTranslate.title]);
                this.form.data.parent_id = record.id;
                this.form.data.sort = (record.children.length + 1);
            }

            this.showModal(this.$refs.directoryModal);
        },
        fetchDirectory(record){
            this.form.updateStatus = true;
            this.axios.get(this.endpoints['module_lecture_directory']+ '/' + record.id).then(response => {
                let data = response.data.data;

                let translate = this.resolveDatum(data.translations, this.$root.defaultLanguage.id, 'language_id');

                this.form.title =  this.sprintf(this.$t("pages.module.lecture.directory.editDirectory"), [translate.title]);
                this.form.translate = translate;
                this.form.data = response.data.data;

                this.showModal(this.$refs.directoryModal);
            });
        },
        onSubmitDirectory(){
            this.$refs.directoryForm.validate((valid) => {
                if(valid) {
                    let formData = this.prepareDirectoryFormData();
                    this.form.loading = true;

                    if(this.form.data.id) {
                        this.axios.put(this.endpoints['module_lecture_directory'] + '/' + this.form.data.id, formData).then(response => {
                            this.onResponse(response.data, () => {
                                if(this.form.updateStatus) {
                                    this.updateDraggableDirectory(this.form.data.id);
                                }
                                this.hideModal(this.$refs.directoryModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }else {
                        this.axios.post(this.endpoints['module_lecture_directory'], formData).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadLectureDirectoryMode();
                                this.hideModal(this.$refs.directoryModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        prepareDirectoryFormData(){
            let formData = this.form.data;

            return {...this.form.translate, ...formData};
        },
        newLecture(directoryRecord) {
            this.form.updateStatus = false;

            this.form.translate = {
                language_id: this.$root.defaultLanguage.id
            };
            this.form.data = {
                product_id: this.productID,
                directory_id: directoryRecord.id,
                sort: (directoryRecord.lectures.length + 1),
                active: true
            };

            let directoryTranslate = this.resolveDatum(directoryRecord.translations, this.$root.defaultLanguage.id, 'language_id')

            this.form.title = this.sprintf(this.$t('pages.module.lecture.save.newLectureWithFolder'), [directoryTranslate.title ?? '']);
            this.showModal(this.$refs.lectureModal);
        },
        onSubmitLecture(){
            this.$refs.lectureForm.validate((valid) => {
                if(valid) {
                    let formData = this.prepareLectureFormData();
                    this.form.loading = true;

                    this.axios.post(this.endpoints['module_lecture'], formData).then(response => {
                        this.onResponse(response.data, () => {
                            this.loadLectureDirectoryMode();
                            this.hideModal(this.$refs.lectureModal);
                        }, () => {
                            this.form.loading = false;
                        });
                    }).catch(error => {
                        this.onResponseFailure(error.response.data, () => {
                            this.form.loading = false;
                        });
                    });

                } else {
                    return false;
                }
            });
        },
        prepareLectureFormData(){
            let formData = this.form.data;

            return {...this.form.translate, ...formData};
        },
        deleteDirectoryRecord(id){
            this.$store.dispatch("module/lecture/directory/delete", {
                id: id
            }).then((successDeleted) => {
                if(successDeleted) {
                    this.loadLectureDirectoryMode();
                }
            });
        },
        deleteLectureRecord(id){
            this.$store.dispatch("module/lecture/delete", {
                id: id
            }).then((successDeleted) => {
                if(successDeleted) {
                    this.loadLectureDirectoryMode();
                }
            });
        },
        updateDraggableDirectory(directoryID){
            this.axios.get(this.endpoints['module_lecture_directory']+ '/' + directoryID).then(response => {
                let data = response.data.data;

                this.directories[directoryID] = data;
            });
        }
    }
}
</script>

<style scoped>

</style>