<template>
    <draggable class="dragArea py-4" tag="div" :list="list" :group="{ name: 'g1' }" item-key="id" @change="(event) => changedEvent(event, parentDirectory, 'Directory')">
        <template #item="{ element, index }">
            <div class="directory mx-4" :class="{'mt-2': index != 0 }">
                <div class="directory-header d-flex justify-content-between align-items-center">
                    <div class="directory-title d-flex align-items-center" v-if="directories[element.id]">
                        <div class="d-flex collapsible rotate cursor-pointer" v-bind:class="{collapsed: !openedExpandDirectory(element.id)}" v-on:click="handleExpandDirectory(element)">
                            <div class="me-3 rotate-90 d-none d-sm-block">
                                <span class="svg-icon svg-icon-3">
                                    <inline-svg src="/media/icons/duotune/arrows/arr071.svg" />
                                </span>
                            </div>
                            <h3 class="text-dark mb-0">{{ resolveDatum(directories[element.id].translations, $root.defaultLanguage.id, 'language_id').title }}</h3>
                        </div>
                        <div>
                            <span :class="directories[element.id].active ? 'badge-light-success': 'badge-light-danger'" class="badge ms-2">{{ directories[element.id].active ? $t('common.active') : $t('common.passive') }}</span>
                        </div>
                    </div>
                    <div class="directory-toolbar mt-1 mt-sm-0">
                        <button type="button" class="btn btn-sm btn-icon btn-primary" data-kt-menu-trigger="click" data-kt-menu-overflow="true" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
                            <span class="svg-icon svg-icon-2 me-0">
                                <inline-svg src="/media/icons/duotune/general/gen024.svg" />
                            </span>
                        </button>
                        <div :id="'directory-dropdown-' + element.id" class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold pb-4 w-250px fs-6" data-kt-menu="true">
                            <div class="px-7 pt-5 pb-2 text-center">
                                <div class="fs-5 text-dark fw-bolder">{{ $t('pages.module.lecture.directoryMode.chooseAction') }}</div>
                            </div>

                            <div class="separator my-3"></div>

                            <div class="menu-item px-5">
                                <a v-on:click="newDirectory(element)" class="menu-link px-5">{{ $t('pages.module.lecture.directoryMode.newDirectory') }}</a>
                                <a v-on:click="fetchDirectory(element)" class="menu-link px-5">{{ $t('common.edit') }}</a>
                                <a v-on:click="newLecture(element)" class="menu-link px-5">{{ $t('pages.module.lecture.directoryMode.newLecture') }}</a>
                            </div>

                            <div class="separator my-3"></div>

                            <div class="menu-item px-5">
                                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteDirectory(element)">
                                    <template #reference>
                                        <a class="menu-link text-danger px-5">{{ $t('common.remove') }}</a>
                                    </template>
                                </el-popconfirm>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="directory-body" v-if="openedExpandDirectory(element.id)">
                    <div class="mt-4 mb-4">
                        <draggable class="list-group" :list="element.lectures" group="people" handle=".handle" item-key="id" @change="(event) => changedEvent(event, element, 'Lecture')">
                            <template #item="{ element, index }">
                                <div class="card mb-3">
                                    <div class="card-header border-0 px-3 min-h-25px py-2 handle">
                                        <div class="card-title m-0" v-if="lectures[element.id]">
                                            <div class="d-flex align-items-center collapsible rotate cursor-pointer" v-bind:class="{collapsed: !openedExpandLecture(element.id)}" v-on:click="handleExpandLecture(element)">
                                                <div class="me-3 rotate-90 d-none d-sm-block">
                                                    <span class="svg-icon svg-icon-3">
                                                        <inline-svg src="/media/icons/duotune/arrows/arr071.svg" />
                                                    </span>
                                                </div>
                                                <h4 class="fw-bolder m-0">{{ resolveDatum(lectures[element.id].translations, $root.defaultLanguage.id, 'language_id').title }}</h4>
                                            </div>
                                            <span :class="lectures[element.id].active ? 'badge-light-success': 'badge-light-danger'" class="badge ms-2">{{ lectures[element.id].active ? $t('common.active') : $t('common.passive') }}</span>
                                            <el-tooltip v-if="lectures[element.id].documents_count" effect="light" :content="$t('pages.module.lecture.directoryMode.documentCount')" placement="bottom">
                                                <a class="d-flex align-items-center text-gray-400 text-hover-primary ms-5 cursor-pointer lh-1">
                                                    <span class="svg-icon svg-icon-2 me-1">
                                                        <inline-svg src="/media/icons/duotune/files/fil003.svg" />
                                                    </span>
                                                    <span>{{ lectures[element.id].documents_count }}</span>
                                                </a>
                                            </el-tooltip>
                                        </div>
                                        <div class="card-toolbar">
                                            <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteLectureRecord([element.id])">
                                                <template #reference>
                                                    <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                                        <span class="svg-icon svg-icon-3">
                                                            <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                                                        </span>
                                                    </a>
                                                </template>
                                            </el-popconfirm>
                                        </div>
                                    </div>
                                    <div class="card-body border-top px-9 pt-3 pb-4" v-if="openedExpandLecture(element.id)">
                                        <Detail :lectureID="element.id"></Detail>
                                    </div>
                                </div>
                            </template>
                        </draggable>
                    </div>

                    <DraggableNested :list="element.children" :parentDirectory="element"/>
                </div>
            </div>
        </template>
    </draggable>
</template>

<script>
import draggable from "vuedraggable";
import Detail from  "@/components/module/lecture/Detail"
import {MenuComponent} from "@/assets/js/components/MenuComponent";

export default {
    name: "DraggableNested",
    props: {
        parentDirectory: { required: true, type: Object },
        list: { required: true, type: Array }
    },
    components: {
        draggable,
        Detail
    },
    inject: ['newDirectory', 'fetchDirectory', 'deleteDirectoryRecord', 'newLecture', 'deleteLectureRecord', 'directories', 'lectures'],
    data(){
        return {
            expandDirectory: {
                opened: []
            },
            expandLecture: {
                opened: []
            }
        }
    },
    created(){
        this.list.forEach((item, itemIndex) => {
            this.directories[item.id] = item;

            item.lectures.forEach((lecture, lectureIndex) => {
                this.lectures[lecture.id] = lecture;
            });
        })
    },
    mounted(){
        setTimeout(() => {
            MenuComponent.reinitialization();
        }, 500);
    },
    methods: {
        openedExpandDirectory(recordID) {
            return !this.expandDirectory.opened.includes(recordID);
        },
        handleExpandDirectory(record) {
            let x = this.expandDirectory.opened.indexOf(record.id);

            if(x === -1) {
                this.expandDirectory.opened.push(record.id);
            } else {
                this.expandDirectory.opened.splice(x, 1);
            }
        },
        openedExpandLecture(recordID) {
            return this.expandLecture.opened.includes(recordID);
        },
        handleExpandLecture(record) {
            let x = this.expandLecture.opened.indexOf(record.id);

            if(x === -1) {
                this.expandLecture.opened.push(record.id);
            } else {
                this.expandLecture.opened.splice(x, 1);
            }
        },
        changedEvent(event, customArgs, functionPrefix = ''){
            let eventArr = Object.entries(event);
            let functionName = this[((eventArr[0][0]) + functionPrefix)];
            if(functionName) {
                functionName(eventArr[0][1], customArgs);
            }
        },
        movedDirectory(args, parentDirectory){
            this.submitMultiSort(this.endpoints['module_lecture_directory_multi_sort'], parentDirectory.children);
        },
        addedDirectory(args, parentDirectory){
            let directory = args.element;
            let data = {
                id: directory.id,
                parent_id: parentDirectory ? parentDirectory.id : null
            };

            this.axios.put(this.endpoints['module_lecture_directory_patch'] + '/' + data.id, data).then(response => {
                if(response.data.status){
                    this.submitMultiSort(this.endpoints['module_lecture_directory_multi_sort'], (parentDirectory ? parentDirectory.children : this.list));
                }

                this.onResponse(response.data, undefined, undefined, false);
            }).catch(error => {
                this.onResponseFailure(error.response.data);
            });
        },
        movedLecture(args, directory){
            this.submitMultiSort(this.endpoints['module_lecture_multi_sort'], directory.lectures);
        },
        addedLecture(args, directory){
            let lecture = args.element;
            let data = {
                id: lecture.id,
                directory_id: directory.id
            };

            this.axios.put(this.endpoints['module_lecture_patch'] + '/' + data.id, data).then(response => {
                if(response.data.status){
                    this.submitMultiSort(this.endpoints['module_lecture_multi_sort'], directory.lectures);
                }

                this.onResponse(response.data, undefined, undefined, false);
            }).catch(error => {
                this.onResponseFailure(error.response.data);
            });
        },
        deleteDirectory(directory){
            let selector = '#directory-dropdown-' + directory.id;
            let menu = MenuComponent.createInsance(selector);

            menu.hide(document.querySelector(selector));

            this.deleteDirectoryRecord([directory.id])
        },
        submitMultiSort(endpoint, data){
            let items = data.map((item, itemIndex) => {
                return {
                    id: item.id,
                    sort: (itemIndex + 1)
                }
            });

            this.axios.post(endpoint, { items: items }).then(response => {
                this.onResponse(response.data);
            }).catch(error => {
                this.onResponseFailure(error.response.data);
            });
        },
    }
}
</script>

<style>
.dragArea {
    min-height: 50px;
    outline: 1px dashed;
}
.directory-pop button.el-button--text{
    color: rgba(0,0,0,.65);
    border: 1px solid #d9d9d9;
    padding: 7px 15px;
}

.directory-pop button.el-button--text:hover{
    color: #40a9ff;
    border-color: #40a9ff;
}

</style>